/* Custom scrollbar for horizontal scrolling */
.scrollbar-custom::-webkit-scrollbar {
    height: 8px;
    width: 4px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #7C50DD;
    border-radius: 4px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
}





.scrollbar-custom1::-webkit-scrollbar {
   display: none;
}

.scrollbar-custom1::-webkit-scrollbar-thumb {
    background-color: #7C50DD;
    border-radius: 4px;
}

.scrollbar-custom1::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
}
