.active-item{
    background-color: white;
    border-radius: 9999px;
    color: #7C50DD;
    
}

.scrollbar-hide {
    -ms-overflow-style: none;  /* For Internet Explorer */
    scrollbar-width: none;  /* For Firefox */
  }


@media screen and (max-width: 767px) {
    .active-item{
        background: none;
    }
  }