

.shine-text {
    padding: 10px 0;
	margin: 0;
	font-weight: bold;
	background-image: linear-gradient(
		70deg,
		#696969
        45%, /* font color */
		#FFF 50%,
		#696969
        55% /* font color */
	);
	background-size: 500% 100%;
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	animation: shine 2s infinite;
}

@keyframes shine {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
