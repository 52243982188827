@keyframes ripple {
    0% {
      transform: scale(0);
      opacity: 0.6;
    }
    100% {
      transform: scale(4);
      opacity: 0;
    }
  }
  
  .animate-ripple {
    position: absolute;
    border-radius: 50%;
    animation: ripple 0.6s linear;
  }
  

  .date-range[type="date"]::-webkit-calendar-picker-indicator{
    display: none;
    -webkit-appearance: none;
  }