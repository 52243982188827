@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap'); */
@import "~@flaticon/flaticon-uicons/css/all/all";



body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "DM Sans", sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .chatbot-loader {
    @apply z-50 fixed inset-0 flex justify-center items-center bg-black bg-opacity-50;
  }

  .chatbot-content {
    @apply relative flex justify-center items-center w-72 h-72 animate-up-down;
  }

  .chatbot-item {
    @apply relative flex justify-center items-center w-40 h-40 animate-up-down;
  }

  .chatbot-body {
    @apply absolute border-12 border-gray-700 border-solid rounded-full w-36 h-20 animate-up-down;
  }
}

.react-datepicker-wrapper{
  all: unset;
  width: 100% !important
}


.sketch-picker .rgb {
  display: none; /* Hide RGB inputs */
}
