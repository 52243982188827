

.skeleton {
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, #D9D9D980 25%, #F8F7F7 50%, #D9D9D980 75%);
    background-size: 200% 100%;
    animation: shine 1.5s infinite;
  }

  @keyframes shine {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

