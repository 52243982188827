.bg-linear-gradient {
    background: linear-gradient(163.6deg, #6B41FF 27%, #F64CFF 68%, #FFC553 100%);
    border-radius: 241.5px; 
    width: 425.18px; 
    height: 446.65px; 
    position: absolute; 
    top: 50%; 
    right: 2%; 
    transform: translateY(-50%); 
    filter: blur(50px); 
    z-index: -1; 
}


.main-container{
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
}


.working-area {
    width: 100%; /* Set to your desired width */
    min-height: 467px;
    height: auto;
    background-color: rgba(236, 234, 234, 0.8); /* Set background to transparent */
    box-shadow: inset 0px 4px 8px rgba(236, 234, 234, 0.9); /* Inner shadow */
    border-radius: 10px; /* Optional: for rounded corners */
}



