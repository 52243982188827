.ql-toolbar,
.emoji-btn,
#toolbar {
    border: none !important;
    border-radius: 0.375rem !important;
}


.ql-editor {
    visibility: visible;
    min-height: 10px;  /* Ensure the editor is always visible */
    max-height: 9rem;  /* Prevent excessive expansion */
    overflow-y: auto;
    max-width: 515px;
  }
  

.emoji-btn {
    color: black !important;
    margin-top: -3px !important;
}

.ql-snow {
    border-radius: 10px;
    width: 100%;
}