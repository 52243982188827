@tailwind base;
@tailwind components;
@tailwind utilities;


.login-description {
    background-image: url("https://cdn.shopify.com/s/files/1/0667/7112/4443/files/BannerImg.fbde4c51655c6a16525e_1_1.jpg?v=1733488094");    
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; 
    width: 100%;
}


