.scroll-bar-y::-webkit-scrollbar {
    width: 5px; 
    padding-right: 12px;
}

.scroll-bar-y::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px; 
}

.scroll-bar-y::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.scroll-bar-y::-webkit-scrollbar-track {
    background: #f1f1f1; 
}


.connection-tree-outline {
    position: relative;
}


.connection-tree-outline:before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(50% + 18px);
    background-color: #BDBDBD;
    left: -15px;
    top: -18px;
}

.connection-tree-outline:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 1px;
    background-color: #BDBDBD;
    left: -15px;
    top: 50%;
}

.convoflow-horizontal-screen-extent{
    width: fit-content;
    align-items: stretch;
    min-width: 400px;
}

.convoflow-popup-window {
    width: auto;
    max-width: 1200px;
    min-width: min(100%, 800px);
}

.ql-tooltip {
    z-index: 9999 !important; /* Ensure link tooltip is on top */
    margin-left: 50%;
  }

  .ql-toolbar
{
    margin-bottom: 0;
}